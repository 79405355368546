const searchMenuItems = []

searchMenuItems.push({
  route: 'searchInstances',
  title: 'menu.searchInstancesList',
})

searchMenuItems.push({
  route: 'searchImprovements',
  title: 'menu.searchImprovements',
  children: [
    {
      route: 'searchSynonyms',
      title: 'menu.searchSynonyms',
    },
    {
      route: 'searchBlockedPhrase',
      title: 'menu.searchBlockedPhrase',
    },
    {
      route: 'searchImmutablePhrase',
      title: 'menu.searchImmutablePhrase',
    },
  ],
})

searchMenuItems.push({
  route: 'searchInstanceSettings',
  title: 'menu.searchInstanceSettings',
  children: [
    {
      route: 'searchWeightsSettings',
      title: 'menu.searchWeights',
    },
    {
      route: 'searchFacetedSettings',
      title: 'menu.searchFacetedMenu',
    },
    {
      route: 'searchSortSettings',
      title: 'menu.searchSort',
    },
    {
      route: 'searchSuggestionSettings',
      title: 'menu.searchSuggestion',
    },
  ],
})

searchMenuItems.push({
  route: 'searchApp',
  title: 'menu.searchApp',
  children: [
    {
      route: 'appV3Settings',
      title: 'menu.appV3Settings',
    },
    {
      route: 'appV3Labels',
      title: 'menu.appV3Labels',
    },
  ],
})

searchMenuItems.push({
  route: 'searchV3Reports',
  title: 'menu.searchV3.reports.main',
  children: [
    {
      route: 'searchV3AllReports',
      title: 'menu.searchV3.reports.allReports',
    },
    {
      route: 'searchV3AllPhrasesReport',
      title: 'menu.searchV3.reports.allPhrasesReport',
    },
    {
      route: 'searchV3EmptySearchPhrasesReport',
      title: 'menu.searchV3.reports.emptySearchPhrasesReport',
    },
  ],
})

searchMenuItems.push({
  route: 'searchTry',
  title: 'menu.searchTry',
  slug: 'searchTry',
})

const searchMenu = {
  title: 'menu.smartSearchV3',
  icon: 'SearchIcon',
  i18n: 'menu.smartSearchV3',
  slug: 'smartSearchV3',
  children: searchMenuItems,
}

export default searchMenu
