const mailingMenu = {
  title: 'menu.mailing',
  icon: 'MailIcon',
  i18n: 'menu.mailing',
  slug: 'mailing',
  children: [{
    route: 'htmlTemplate',
    title: 'menu.htmlTemplate',
  }],
}

export default mailingMenu
