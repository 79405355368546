<template>
  <div>
    <div>
      {{ $t('searchSelect.description') }}
    </div>
    <vs-select
      :value="getSelectedInstance"
      @input="onChangeInstance($event)"
    >
      <vs-select-item
        v-for="(element) in getInstances"
        :key="element.instanceId"
        :text="element.instanceName"
        :value="element.instanceId"
      />
    </vs-select>
  </div>
</template>

<script>
import SearchInstanceStore from '@/store/search/searchInstance'
import SearchInstancesStore from '@/store/search/searchInstances'

export default {
  name: 'SelectSearchList',
  components: {
  },
  computed: {
    getInstances() {
      return SearchInstancesStore.state.instances
    },
    getSelectedInstance() {
      return SearchInstanceStore.state.searchInstanceId
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      return SearchInstancesStore.dispatch('getSearchInstancesData')
    },
    onChangeInstance(value) {
      SearchInstanceStore.commit('setSearchInstanceId', value)
      this.$emit('input', value)
    },
  },
}
</script>
