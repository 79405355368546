<template>
  <vs-row vs-w="8">
    <DataSourceSelect class="mr-2" />
    <DataSourceDatesRangeSelect />
  </vs-row>
</template>

<script>
import DataSourceSelect from './Navbar/DataSourceSelect.vue'
import DataSourceDatesRangeSelect from './Navbar/DataSourceDatesRangeSelect.vue'

export default {
  name: 'SearchHeader',
  components: {
    DataSourceSelect, DataSourceDatesRangeSelect,
  },
}
</script>
