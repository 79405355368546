const catalogMenuItems = []

catalogMenuItems.push({
  route: 'catalogInstances',
  title: 'menu.catalogInstances',
  slug: 'catalogInstances',
})

catalogMenuItems.push({
  route: 'searchSharedFieldsSettings',
  title: 'menu.searchSharedFields',
})

const catalogMenu = {
  title: 'menu.searchCatalog',
  icon: 'TagIcon',
  i18n: 'menu.searchCatalog',
  children: catalogMenuItems,
}

export default catalogMenu
