import store from '@/store'
import searchMenuV2 from './searchMenuV2'
import catalogMenu from './catalogMenu'
import mailingMenu from './mailingMenu'
import searchMenuV3 from './searchMenuV3'
import stylistMenu from './stylistMenu'

const mainMenu = []

if (store.state.user.appActiveUser.features.includes('enabled_searchv2')) {
  mainMenu.push(searchMenuV2)
}

if (store.state.user.appActiveUser.features.includes('enabled_searchv3')) {
  if (store.state.user.appActiveUser.modules.includes('catalog')) {
    mainMenu.push(catalogMenu)
  }

  if (store.state.user.appActiveUser.modules.includes('search')) {
    mainMenu.push(searchMenuV3)
  }

  // Stylista
  if (store.state.user.appActiveUser.modules.includes('stylist')) {
    mainMenu.push(stylistMenu)
  }

  if (store.state.user.appActiveUser.modules.includes('mailing')) {
    mainMenu.push(mailingMenu)
  }

  // dostęp do api
  mainMenu.push({
    route: 'searchApiAccess',
    title: 'menu.searchAuthorizationSettings',
    icon: 'TerminalIcon',
  })
}

export default mainMenu
