<template>
  <p class="clearfix mb-0">
    <span>&copy;</span>
    <span>{{ new Date().getFullYear() }} </span>
    <b-link href="https://quarticon.com" target="_blank">QuarticOn</b-link>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
