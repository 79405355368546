<template>
  <vs-row vs-w="8">
    <DataSourceDatesRangeSelect />
  </vs-row>
</template>

<script>
import DataSourceDatesRangeSelect from './Navbar/DataSourceDatesRangeSelect.vue'

export default {
  name: 'SearchV3Header',
  components: {
    DataSourceDatesRangeSelect,
  },
}
</script>
