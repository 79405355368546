<template>
  <b-navbar-nav class="nav align-items-center ml-auto i18n-element">
    <b-nav-item-dropdown class="w-48 i18n-dropdown vx-navbar-dropdown">
      <template #button-content>
        <span class="cursor-pointer flex items-center i18n-locale">
          <img class="mr-1" :src="getImage" :alt="getLocale">
          <span>{{ getLanguage }}</span>
        </span>
      </template>
      <b-dropdown-item @click="updateLocale('en')">
        <img class="h-4 w-5 mr-1" src="@/assets/images/flags/en.png" alt="en">
        <span>English</span>
      </b-dropdown-item>
      <b-dropdown-item @click="updateLocale('cz')">
        <img class="h-4 w-5 mr-1" src="@/assets/images/flags/cz.png" alt="cz">
        <span>Czech</span>
      </b-dropdown-item>
      <b-dropdown-item @click="updateLocale('pl')">
        <img class="h-4 w-5 mr-1" src="@/assets/images/flags/pl.png" alt="pl">
        <span>Polski</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
import store from '@/store'

export default {
  name: 'I18nNavBar',
  computed: {
    getLanguage() {
      return store.getters['locale/getLanguages']
    },
    getLocale() {
      return store.getters['locale/getLocale']
    },
    getImage() {
      return require('@/assets/images/flags/' + store.getters['locale/getLocale'] + '.png')
    },
  },
  mounted() {
    this.initLocale()
  },
  methods: {
    initLocale() {
      const locale = store.getters['locale/getLocale']
      this.updateLocale(locale)
    },
    updateLocale(locale) {
      this.$i18n.locale = locale
      store.commit('locale/setLocale', locale)
    },
  },
}
</script>

<style scoped>
.i18n-element {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 13px;
}

img {
  height: 1rem;
  width: 1.25rem;
}
</style>
