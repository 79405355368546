<template>
  <div>
    <label class="mr-1" for="dateRange">{{ $t('search.header.dateRange') }}</label>
    <flat-pickr
      id="dateRange"
      :config="configDateRangePicker"
      :value="date"
      @on-change="onDateRangeChange"
    />
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { Polish } from 'flatpickr/dist/l10n/pl.js'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'
import store from '@/store'

export default {
  name: 'DataSourceDatesRangeSelect',
  components: {
    flatPickr,
  },
  computed: {
    date() {
      return ''
    },
    configDateRangePicker() {
      return {
        mode: 'range',
        dateFormat: 'Y-m-d',
        maxDate: moment().toDate(),
        locale: store.state.locale.locale === 'pl' ? Polish : null,
        defaultDate: [
          store.state.search.dateRange.start,
          store.state.search.dateRange.end,
        ],
      }
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    onDateRangeChange(selectedDates) {
      if (selectedDates.length !== 2) {
        return
      }

      const startDate = this.formatDate(selectedDates[0])
      if (store.state.search.dateRange.start !== startDate) {
        store.commit('search/setDateStart', startDate)
      }

      const endDate = this.formatDate(selectedDates[1])
      if (store.state.search.dateRange.end !== endDate) {
        store.commit('search/setDateEnd', endDate)
      }
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.flatpickr-days {
  .flatpickr-day.startRange, .flatpickr-day.endRange {

    background: $primary;
    border-color: $primary;

    &:hover {
      background: $primaryDarker;
      background-color: $primaryDarker;

    }
  }
}

.flatpickr-input {
  padding: .5rem .5rem .4rem .5rem;
  font-size: .9rem;
  min-width: 13.3rem;
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}
</style>
