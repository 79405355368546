<template>
  <b-navbar-nav class="nav align-items-center ml-auto" v-if="activeUserInfo.displayName">
    <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link">
      <template #button-content>
        <div class="d-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0">
            {{ activeUserInfo.displayName }}
          </p>
<!--          <span class="user-status">Admin</span>-->
        </div>
        <user-icon />
      </template>
      <b-dropdown-item link-class="d-flex align-items-center" @click="goToStage">
        <external-link-icon />
        <span class="ml-2">{{ $t('profile.goToStage') }}</span>
      </b-dropdown-item>
      <b-dropdown-divider />
      <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
        <log-out-icon />
        <span class="ml-2">{{ $t('profile.logout') }}</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </b-navbar-nav>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import AuthService from '@/auth/qonJwt/authService'
import { UserIcon, ExternalLinkIcon, LogOutIcon } from 'vue-feather-icons'
import store from '@/store'

export default {
  components: { UserIcon, ExternalLinkIcon, LogOutIcon },
  data() {
    return {}
  },
  computed: {
    activeUserInfo() {
      return store.state.user.appActiveUser
    },
  },
  watch: {
    activeUserInfo() {
      return store.state.user.appActiveUser
    },
  },
  methods: {
    logout() {
      const authService = new AuthService()
      authService.logout()
        .then(() => {
          window.location.href = process.env.VUE_APP_OLD_PANEL_URL
        })
        .catch(() => {
          window.location.href = process.env.VUE_APP_OLD_PANEL_URL
        })
    },
    goToStage() {
      window.location.href = process.env.VUE_APP_OLD_PANEL_URL + '/qonpanel/goToStage'
    },
    setup() {
      const { skin } = useAppConfig()
      const isDark = computed(() => skin.value === 'dark')
      return { skin, isDark }
    },
  },
}
</script>

<style>
  .user-profile-dropdown .vs-dropdown--custom {
    padding: 2px 2px 2px 2px !important;
  }
  .user-profile-dropdown .vs-dropdown--custom ul {
    min-width: 9rem;
    font-size: .9rem;
    position: relative;
    z-index: 11;
  }
</style>
