const stylistMenuItems = []
stylistMenuItems.push({
  route: 'stylistReports',
  title: 'menu.smartStylist.reports',
  slug: 'stylistReports',
})

stylistMenuItems.push({
  route: 'stylizationsList',
  title: 'menu.smartStylist.stylizationsList',
  slug: 'stylizationsList',
})

const stylistMenu = {
  title: 'menu.smartStylist.main',
  icon: 'CpuIcon',
  i18n: 'menu.smartStylist',
  slug: 'smartStylist',
  children: stylistMenuItems,
}

export default stylistMenu
