<template>
  <div>
    <label class="mr-1" for="dataSource">{{ $t('search.header.dataSource') }}</label>
    <v-select
      id="dataSource"
      v-model="activeDataSource"
      :options="dataSources"
      :clearable="false"
      :searchable="false"
      :clear-search-on-select="false"
      :reset-on-options-change="false"
      class="inline-block"
      @input="setActiveDataSource"
    />
  </div>
</template>
<script>
import vSelect from 'vue-select'
import store from '@/store'

export default {
  name: 'DataSourceSelect',
  components: {
    vSelect,
  },
  computed: {
    activeDataSource: {
      get() {
        return store.state.search.activeDataSource || {
          value: 'all',
          label: this.$t('search.dataSources.allData'),
        }
      },
      set(value) {
        store.commit('search/setActiveDataSource', value)
      },
    },
    dataSources() {
      return [
        {
          value: 'all',
          label: this.$t('search.dataSources.allData'),
        },
        {
          value: 'autocomplete',
          label: this.$t('search.dataSources.autocomplete'),
        },
        {
          value: 'serp',
          label: this.$t('search.dataSources.serp'),
        },
        {
          value: 'popular',
          label: this.$t('search.dataSources.popular'),
        },
        {
          value: 'bestseller',
          label: this.$t('search.dataSources.bestseller'),
        },
      ]
    },
  },
  methods: {
    setActiveDataSource(value) {
      store.commit('search/setActiveDataSource', value)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#dataSource {
  display: inline-block;
}

.v-select .vs__selected-options {
  min-width: 72px;
}

.v-select .vs__dropdown-toggle {
  padding-bottom: 2px;
}

.v-select .vs__dropdown-toggle .vs__search {
  height: auto !important;
}

.vs--single.vs--open .vs__selected {
  white-space: nowrap;
  position: relative;
}
</style>
