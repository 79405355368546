import store from '@/store'

const searchMenuItems = []
searchMenuItems.push({
  route: 'searchDashboard',
  title: 'menu.smartSearchDashboard',
})
searchMenuItems.push({
  route: 'searchPhrasesReport',
  title: 'menu.searchReports',
  children: [
    {
      route: 'searchPhrasesReport',
      title: 'menu.searchPhrasesReport',
    },
    {
      route: 'searchPhrasesReportWithNoResults',
      title: 'menu.searchPhrasesReportWithNoResults',
    },
    {
      route: 'searchProductsReport',
      title: 'menu.searchProductsReport',
    },
  ],
})

searchMenuItems.push({
  route: 'searchSynonyms',
  title: 'menu.searchSynonyms',
})

if (process.env.VUE_APP_SHOW_BLOCKED_PHRASES !== 'false') {
  searchMenuItems.push({
    route: 'searchBlockedPhrase',
    title: 'menu.searchBlockedPhrase',
    slug: 'blockedPhrase',
  })
}
if (process.env.VUE_APP_SHOW_BLOCKED_INFLECTION !== 'false') {
  searchMenuItems.push({
    route: 'searchImmutablePhrase',
    title: 'menu.searchImmutablePhrase',
    slug: 'immutablePhrase',
  })
}

searchMenuItems.push({
  route: 'searchSettings',
  title: 'menu.searchSettings',
  slug: 'settings',
})

if (store.getters.userData?.platformId === 218) {
  searchMenuItems.push({
    route: 'searchSubscription',
    title: 'menu.searchSubscription',
    slug: 'subscription',
  })
}

const searchMenu = {
  title: 'menu.smartSearch',
  icon: 'SearchIcon',
  i18n: 'menu.smartSearch',
  slug: 'smartSearch',
  children: searchMenuItems,
}

export default searchMenu
