<template>
  <div>
    <b-alert
      v-for="(error, index) in errors" :key="`main-error-${index}`"
      show
      dismissible
      fade
      variant="danger"
    >
      <h4 class="alert-heading text-dark">
        {{ $t('alert.error') }}
      </h4>
      <p class="mb-1 p-1 text-sm-left">
        {{ $t(error) }}
      </p>
    </b-alert>
  </div>
</template>
<script>
import Notifications from '@/store/notifications/notifications'

export default {
  name: 'ShowError',

  computed: {
    errors() {
      return Notifications.state.errors
    },
    popupError() {
      return Notifications.state.popupError
    },
    popupInfo() {
      return Notifications.state.popupInfo
    },
  },
  watch: {
    popupError(error) {
      this.$vs.notify({
        title: this.$t('alert.error'),
        text: this.$t(error.message),
        color: 'danger',
        position: 'top-right',
        icon: 'error',
        time: 5000,
      })
    },
    popupInfo(info) {
      this.$vs.notify({
        title: info.name,
        text: info.message,
        color: '#39BA9B',
        position: 'top-right',
        icon: 'done',
        time: 5000,
      })
    },
  },
}
</script>

<style>
.content-noti h3 {
  color: white
}
.mainAlert .material-icons {
  font-size: 14px !important;
}
</style>
